#ctn_open {
    height: 100vh;
    width: 100vw;
}

#ctn_direct {
    /*height: 100vh;*/
    width: 100vw;
    padding-bottom: 20px;
    /*background-color: white;*/
}

#ctn_infos {
    /*height: 100vh;*/
    width: 100vw;
    /*width: 90vw;*/
    /*margin-left: 5vw;*/
    /*height: 100vh;*/
    /*background-color: #00004D;*/
}
