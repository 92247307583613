.ctn_diag {
    height: 300px
}

.div_ctn {
    display: flex;
    flex-direction: row;
}

.div_triangle_left {
    background-color: #00004D;
    width: 70vw;
    height: 300px;
    position: relative;
    left: -20vw;
    z-index: 2;
    transform: skew(20deg);
    transition: all 0.4s;

}

.div_triangle_right {
    background-color: white;
    width: 70vw;
    height: 300px;
    position: relative;
    left: 50vw;
    top: -300px;
    z-index: 2;
    transform: skew(20deg);
    transition: all 0.4s;
}


.div_triangle_left:hover {
    width: 100vw;
    transition: all 0.4s;
    z-index: 4;
}

.div_triangle_right:hover {
    width: 100vw;
    left: 20vw;
    transition: all 0.4s;
    z-index: 4;
}

.content_triangle_left {
    z-index: 5;
    color: white;
    font-size: 32px;
    line-height: 35px;
    position: relative;
    top: -600px;
    /*right: -50px;*/
    width: 100%;
    height: 300px;
    /*background-color: rgba(255, 255, 0, 0.1);*/
}

.content_triangle_right {
    z-index: 5;
    /*color: black;*/
    font-size: 32px;
    line-height: 35px;
    /*line-height: 35px;*/
    position: relative;
    top: -600px;
    /*right: -50px;*/
    /*right: -75vw;*/
    width: 100%;
    /*background-color: rgba(255, 0, 0, 0.1);*/
}

.div_triangle_right:hover ~ .content_triangle_left {
    opacity: 0.5;
}

.div_triangle_left:hover ~ .content_triangle_right {
    opacity: 0.5;
}


.div_img_ctn_right {
    width: 20vw;
    max-width: 200px;
    margin-top: 25px;
    padding-left: 150px;
    margin-bottom: 10px;
    height: 120px;
}

.div_img_ctn_left {
    margin-top: 25px;
    width: 20vw;
    max-width: 200px;
    height: 120px;
}

.div_img_ctn_left img {
    width: 80%;
}

.div_img_ctn_right img {
    width: 80%;
}

.div_ctn_content {
    display: grid;
    grid-template-columns: 1fr 1fr;

}

.test {
    background-image: url("../../assets/images/logo_aix.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 20vw;
    max-width: 200px;
    height: 20vw;
    max-height: 200px;
}


.test2 {
    background-image: url("../../assets/images/cbru_logo_clean.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 20vw;
    max-width: 200px;
    position: relative;
    height: 20vw;
    max-height: 200px;
}
