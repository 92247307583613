.notification-info {
    background-color: #F3F3F3;
}


.notification {
    box-sizing: border-box;
    padding: 15px 15px 15px 58px;
    border-radius: 6px;
    color: black;
    background-color: #F3F3F3;
    /*box-shadow: 0 0 12px #999;*/
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    opacity: 1;
    margin-top: 45px;
    margin-bottom:20px
}
