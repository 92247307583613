#title_direct {
    padding: 0;
    margin-top: 30px;
    margin-bottom: 5px;
    color: #FF5757;
    text-align: center;
    font-size: 44px;
    font-weight: bold;
}

.frame {
    width: 90%;
    height: 60vh;
    margin-top: 4vh;
    margin-left: 5%;
    margin-bottom: 50px;
}

.title_match {
    text-align: center;
    font-size: 28px;
    padding-bottom: 0;
}

.title_score {
    text-align: center;
    font-size: 62px;
    margin-bottom: 15px;
    margin-top: 0;
    padding-top: 0;
    color: #00004D;



}

.p_match {
    width: 80%;
    margin-left: 10%;
    text-align: center;
    margin-top: 0;
    color: #00004D;
    margin-bottom: 40px;
    font-weight: bold;

}
