.grid {
    width: 100vw;
    height: 300px;
    display: grid;
    grid-template-columns: 1fr 1.55fr;
}

#triangle {
    display: inline-block;
    position: absolute;
    height: 0;
    width: 0;
    border-right: 300px solid transparent;
    border-top: 300px solid #00004D;
}

.ctn_right {
    background-image: url("../../assets/images/cbru_logo_clean.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
    height: 150px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: 10vw
}

.ctn_left {
    background-image: url("../../assets/images/logo_aix.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
    height: 150px;
    margin-top: 20px;
    margin-right: auto;
    margin-left: 10vw
}

.text_left {
    margin-top: 40px;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    margin-left: 4vw;
}

.text_right {
    margin-top: 40px;
    font-weight: bold;
    font-size: 36px;
    text-align: right;
    margin-right: 8vw;
}

@media (max-width: 1000px) {
    #triangle {
        display: none;
    }

    .grid {
        grid-template-columns: 1fr 1fr;
        text-align: center;
    }

    .ctn_right {
        margin-right: auto;
        margin-left: auto;
        margin-right:5px
    }

    .ctn_left {

        margin-right: auto;
        margin-left: auto;
    }

    .text_left {
        margin-top: 40px;
        font-weight: bold;
        font-size: 26px;
        text-align: center;
    }

    .text_right {

        font-weight: bold;
        font-size: 26px;
        text-align: center;
        margin: 0;
        margin-top: 40px;
    }

}
